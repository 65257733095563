import { ScriptInjector } from '@storefrontClasses/script_injector/script_injector';
import { getBasketInformation } from '@frontstoreApi/basket';
import { store } from '@frontstoreRwd/store';
import { $emit } from '@core/tools/event_bus';
import { WidgetBasketEvent, WidgetInitOptions } from '@storefrontFeatures/inpost_pay/state/services/inpost_pay_service_types';
import { INPOST_WIDGET_BASKET_EVENTS } from '@storefrontFeatures/inpost_pay/state/services/inpost_pay_service_constants';

export class InpostPayV2 {
    timeoutId: ReturnType<typeof setTimeout> | null = null;
    checkBasketInterval: ReturnType<typeof setInterval> | null = null;
    //@ts-ignore
    canBeBound = Shop.values.requiredVariantsSelected;
    basketId = '';
    inpostBasketId = '';
    stockId: string | null = null;
    selectedOptions: Record<string, string> = {};

    constructor() {
        // @ts-ignore
        const isSandbox = Shop.iziIsSandbox === '1';

        const url = isSandbox
            ? 'https://sandbox-inpostpay-widget-v2.inpost.pl/inpostpay.widget.v2.js'
            : 'https://inpostpay-widget-v2.inpost.pl/inpostpay.widget.v2.js';
        ScriptInjector.create('iziPay', url).then(() => {
            if (this._hasFileVariant()) return;

            this._initInpostWidget();
        });

        // @ts-ignore
        window.shoper.addEvent('requiredVariants:selected', () => {
            this.canBeBound = true;
        });

        // @ts-ignore
        window.shoper.addEvent('requiredVariants:notSelected', () => {
            this.canBeBound = false;
        });

        // @ts-ignore
        window.shoper.addEvent('stock:change', (ev, stock) => {
            this.stockId = stock.sid;
        });

        // @ts-ignore
        window.shoper.addEvent('stock:options:change', (ev, selectedOptionsObjects) => {
            this.selectedOptions = selectedOptionsObjects;
        });

        // @ts-ignore
        if (Shop.selectedOptionsObjects) {
            // @ts-ignore
            this.selectedOptions = Shop.selectedOptionsObjects;
        }

        if (this._hasFileVariant()) {
            const iziButtonsCollection = Array.from(document.getElementsByTagName('inpost-izi-button'));
            iziButtonsCollection.forEach((el) => el.remove());
        }
    }

    private async _initInpostWidget() {
        const widgetOptions: WidgetInitOptions = {
            //@ts-ignore
            merchantClientId: window.Shop.inpostMerchantClientId,
            basketBindingApiKey: this._getBindingApiKey(),
            unboundWidgetClicked: async (productId: string) => this.iziAddToCart(productId),
            handleBasketEvent: async (eventName: WidgetBasketEvent) => {
                switch (eventName) {
                    case INPOST_WIDGET_BASKET_EVENTS.basketProductChanged:
                        {
                            getBasketInformation({
                                // @ts-ignore
                                lang: window.Shop.lang.name,
                                // @ts-ignore
                                currency: window.Shop.values.currency
                            }).then((basket) => {
                                store.basketInfo = basket;
                                $emit('basket:update');
                            });
                        }
                        break;
                    case INPOST_WIDGET_BASKET_EVENTS.orderCreated:
                        {
                            this.iziGetOrderComplete();
                        }
                        break;
                }

                return true;
            }
        };

        window.InPostPayWidget.init(widgetOptions);
    }

    private async _getBindingApiKey() {
        const url = '/webapi/front/inpost/binding';

        try {
            const response = await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            return result.basket_binding_api_key;
        } catch (err) {
            console.log(err);
            return;
        }
    }

    private _hasFileVariant() {
        const $el = document.querySelector('.stock-options [type="file"]');
        return !!$el;
    }

    public async iziGetOrderComplete() {
        const url = '/webapi/front/inpost/order-complete';
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data) {
                if (data.action === 'redirect') {
                    localStorage.removeItem('basketIndex');
                    localStorage.removeItem('frontstoreBasket');
                    window.location.href = data.redirect;
                }
            }

            return data;
        } catch (err) {
            console.error(err);
        }
    }

    public iziCanBeBound = () => {
        if (document.querySelector('inpost-izi-button')?.getAttribute('binding_place') !== 'PRODUCT_CARD') {
            return true;
        }

        if (!this.canBeBound) {
            // @ts-ignore
            window.shoper.alert(Shop.lang.common.product_select_stock);
            return false;
        }

        return true;
    };

    public iziAddToCart = async (productId: string) => {
        if (!this.stockId) {
            this.stockId = productId;
        }

        if (!this.iziCanBeBound()) {
            throw new Error('UNDELIVERABLE_PRODUCT');
        }

        // @ts-ignore
        const response = await fetch(`/webapi/front/${window.Shop.lang.name}/basket/${window.Shop.values.currency}/`, {
            method: 'post',
            body: JSON.stringify({
                quantity: document.querySelector<HTMLInputElement>(`.addtobasket-container [name="quantity"]`)?.value || 1,
                stock_id: this.stockId,
                options: this.selectedOptions
            })
        });

        const data = await response.json();
        if (data) {
            //@ts-ignore
            shoper.fireEvent('cart:add:ajax', $(document.querySelector('.form-basket')));

            store.basketInfo = await getBasketInformation({
                // @ts-ignore
                lang: window.Shop.lang.name,
                // @ts-ignore
                currency: window.Shop.values.currency
            });

            $emit('basket:update');
        }

        return this._getBindingApiKey();
    };
}
