import { ScriptInjector } from '@storefrontClasses/script_injector/script_injector';
import { getBasketInformation } from '@frontstoreApi/basket';
import { store } from '@frontstoreRwd/store';
import { $emit, $on } from '@core/tools/event_bus';

export class InpostPay {
    timeoutId: ReturnType<typeof setTimeout> | null = null;
    checkBasketInterval: ReturnType<typeof setInterval> | null = null;
    //@ts-ignore
    canBeBound = Shop.values.requiredVariantsSelected;
    basketId = '';
    inpostBasketId = '';
    stockId: number | null = null;
    selectedOptions: Record<string, string> = {};

    constructor() {
        // @ts-ignore
        const url = Shop.iziIsSandbox === '1' ? 'https://izi-sandbox.inpost.pl/inpostizi.js' : 'https://izi.inpost.pl/inpostizi.js';
        ScriptInjector.create('iziPay', url).then(() => {
            if (this._hasFileVariant()) return;

            if (typeof window.handleInpostIziButtons === 'function') {
                window.handleInpostIziButtons();
            }
        });

        // @ts-ignore
        window.shoper.addEvent('requiredVariants:selected', () => {
            this.canBeBound = true;
        });

        // @ts-ignore
        window.shoper.addEvent('requiredVariants:notSelected', () => {
            this.canBeBound = false;
        });

        // @ts-ignore
        window.shoper.addEvent('stock:change', (ev, stock) => {
            this.stockId = stock.sid;
        });

        // @ts-ignore
        window.shoper.addEvent('stock:options:change', (ev, selectedOptionsObjects) => {
            this.selectedOptions = selectedOptionsObjects;
        });

        // @ts-ignore
        if (Shop.selectedOptionsObjects) {
            // @ts-ignore
            this.selectedOptions = Shop.selectedOptionsObjects;
        }

        $on('basket:update', () => {
            this._updateCount();
        });

        if (localStorage.getItem('inpostOrderInProgress') === '1') {
            this._isBound().then(() => {
                if (this.checkBasketInterval) {
                    clearInterval(this.checkBasketInterval);
                }

                this.checkBasketInterval = setInterval(() => {
                    getBasketInformation({
                        // @ts-ignore
                        lang: window.Shop.lang.name,
                        // @ts-ignore
                        currency: window.Shop.values.currency
                    }).then((basket) => {
                        store.basketInfo = basket;
                        $emit('basket:update');
                    });

                    this.iziGetOrderComplete();
                }, 10000);
            });
        }

        if (this._hasFileVariant()) {
            const iziButtonsCollection = Array.from(document.getElementsByTagName('inpost-izi-button'));
            iziButtonsCollection.forEach((el) => el.remove());
        }

        if ($('inpost-thank-you').length > 0) {
            const checkInpost = setInterval(() => {
                if (typeof window.handleThankYouNode === 'function') {
                    window.handleThankYouNode();
                    clearInterval(checkInpost);
                }
            }, 200);
        }
    }

    private _hasFileVariant() {
        const $el = document.querySelector('.stock-options [type="file"]');
        return !!$el;
    }

    private _updateCount() {
        const event = new CustomEvent('inpost-update-count', { detail: store.basketInfo!.basket.count });
        const iziButtonsCollection = Array.from(document.getElementsByTagName('inpost-izi-button'));
        iziButtonsCollection.forEach((el) => el.dispatchEvent(event));
    }

    public iziGetPayData = async (prefix: string | undefined, phoneNumber: string | undefined, bindingPlace: string) => {
        const url = '/webapi/front/inpost/pay-data';
        // @ts-ignore
        const browserData = window.iziGetBrowserData();

        const data: Record<string, any> = {
            binding_place: bindingPlace,
            browser: browserData
        };

        if (prefix && phoneNumber) {
            data.phone_number = {
                country_prefix: prefix,
                phone: phoneNumber
            };
        }

        try {
            const response = await fetch(url, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            this.basketId = result.basket_id;

            if (this.basketId) {
                return Promise.resolve(result);
            }

            return Promise.resolve([]);
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    public iziGetIsBound = async () => {
        return new Promise((resolve, reject) => {
            this._setupGetIsBoundPolling(resolve, reject);
        });
    };

    private async _setupGetIsBoundPolling(resolve: (value: unknown) => void, reject: (reason?: any) => void) {
        const url = '/webapi/front/inpost/is-bound';
        localStorage.setItem('inpostOrderInProgress', '1');

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data?.phone_number) {
                if (this.checkBasketInterval) {
                    clearInterval(this.checkBasketInterval);
                }

                this.checkBasketInterval = setInterval(() => {
                    getBasketInformation({
                        // @ts-ignore
                        lang: window.Shop.lang.name,
                        // @ts-ignore
                        currency: window.Shop.values.currency
                    }).then((basket) => {
                        store.basketInfo = basket;
                        $emit('basket:update');
                    });

                    this.iziGetOrderComplete();
                }, 10000);

                this.inpostBasketId = data.inpost_basket_id;

                resolve(data);
            } else if (data?.action) {
                this._handleServerAction(data.action, resolve, reject);
            } else if (data?.error_description) {
                if (this.timeoutId) clearInterval(this.timeoutId);
                reject(new Error(data.error_description));
            } else {
                localStorage.removeItem('inpostOrderInProgress');
                if (this.checkBasketInterval) {
                    clearInterval(this.checkBasketInterval);
                }
                if (this.timeoutId) clearInterval(this.timeoutId);
                this.timeoutId = setTimeout(() => this._setupGetIsBoundPolling(resolve, reject), 10000);
            }
        } catch (err) {
            if (this.timeoutId) clearInterval(this.timeoutId);
            reject(err);
        }
    }

    private _handleServerAction(action: string, resolve: (value: unknown) => void, reject: (reason?: any) => void) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        switch (action) {
            case 'close':
                reject(new Error('Połączenie zostało przerwane, spróbuj ponownie.'));
                break;
            case 'retry':
                this.timeoutId = setTimeout(() => this._setupGetIsBoundPolling(resolve, reject), 1000);
                break;
            default:
                break;
        }
    }

    public async iziGetOrderComplete() {
        const url = '/webapi/front/inpost/order-complete';
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data) {
                if (data.action === 'redirect') {
                    localStorage.removeItem('inpostOrderInProgress');
                    if (this.checkBasketInterval) clearInterval(this.checkBasketInterval);
                    window.location.href = data.redirect;
                }

                if (data.action === 'unbinded') {
                    localStorage.removeItem('inpostOrderInProgress');
                    if (this.checkBasketInterval) clearInterval(this.checkBasketInterval);

                    const iziButtonsCollection = Array.from(document.getElementsByTagName('inpost-izi-button'));

                    iziButtonsCollection.forEach((el) => {
                        const newIziButton = document.createElement('inpost-izi-button');
                        newIziButton.setAttribute('name', '');
                        newIziButton.setAttribute('masked_phone_number', '');
                        newIziButton.setAttribute('data-product-id', el.getAttribute('data-product-id')!);
                        newIziButton.setAttribute('language', el.getAttribute('language')!);
                        newIziButton.setAttribute('variant', el.getAttribute('variant')!);
                        newIziButton.setAttribute('basket', el.getAttribute('basket')!);
                        newIziButton.setAttribute('dark_mode', el.getAttribute('dark_mode')!);
                        newIziButton.setAttribute('count', String(store.basketInfo!.basket.count));
                        newIziButton.setAttribute('frame_style', el.getAttribute('frame_style')!);
                        newIziButton.setAttribute('binding_place', el.getAttribute('binding_place')!);

                        if (document.body.classList.contains('shop_basket')) {
                            newIziButton.classList.add('izi-button-basket');
                        }

                        el.replaceWith(newIziButton);
                    });

                    // @ts-ignore
                    if (typeof window.handleInpostIziButtons === 'function') {
                        window.handleInpostIziButtons();
                    }
                }
            }

            return data;
        } catch (err) {
            console.error(err);
        }
    }

    public async iziBindingDelete() {
        const url = '/webapi/front/inpost/binding';
        try {
            const response = await fetch(url, {
                method: 'delete'
            });
            const data = await response.json();

            return data;
        } catch (err) {
            console.error(err);
        }
    }

    public iziCanBeBound = async () => {
        await this._isBound();

        if (document.querySelector('inpost-izi-button')?.getAttribute('binding_place') !== 'PRODUCT_CARD') {
            return true;
        }

        if (!this.canBeBound) {
            // @ts-ignore
            window.shoper.alert(Shop.lang.common.product_select_stock);
            return false;
        }

        return true;
    };

    private _isBound = async () => {
        const url = '/webapi/front/inpost/is-bound';

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data && data.masked_phone_number) {
                this.inpostBasketId = data.inpost_basket_id;
                const iziButtonsCollection = Array.from(document.getElementsByTagName('inpost-izi-button'));

                iziButtonsCollection.forEach((el) => {
                    const newIziButton = document.createElement('inpost-izi-button');
                    newIziButton.setAttribute('name', data.name);
                    newIziButton.setAttribute('masked_phone_number', data.masked_phone_number);
                    newIziButton.setAttribute('data-product-id', el.getAttribute('data-product-id')!);
                    newIziButton.setAttribute('language', el.getAttribute('language')!);
                    newIziButton.setAttribute('variant', el.getAttribute('variant')!);
                    newIziButton.setAttribute('basket', el.getAttribute('basket')!);
                    newIziButton.setAttribute('dark_mode', el.getAttribute('dark_mode')!);
                    newIziButton.setAttribute('count', String(store.basketInfo!.basket.count));
                    newIziButton.setAttribute('frame_style', el.getAttribute('frame_style')!);
                    newIziButton.setAttribute('binding_place', el.getAttribute('binding_place')!);

                    if (document.body.classList.contains('shop_basket')) {
                        newIziButton.classList.add('izi-button-basket');
                    }

                    el.replaceWith(newIziButton);
                });

                // @ts-ignore
                if (typeof window.handleInpostIziButtons === 'function') {
                    window.handleInpostIziButtons();
                }
            } else {
                localStorage.removeItem('inpostOrderInProgress');
            }
        } catch (err) {
            console.error(err);
        }
    };

    public iziAddToCart = async (productId: number) => {
        if (!this.stockId) {
            this.stockId = productId;
        }

        // @ts-ignore
        const response = await fetch(`/webapi/front/${window.Shop.lang.name}/basket/${window.Shop.values.currency}/`, {
            method: 'post',
            body: JSON.stringify({
                quantity: document.querySelector<HTMLInputElement>(`.addtobasket-container [name="quantity"]`)?.value || 1,
                stock_id: this.stockId,
                options: this.selectedOptions
            })
        });

        const data = await response.json();
        if (data) {
            //@ts-ignore
            shoper.fireEvent('cart:add:ajax', $(document.querySelector('.form-basket')));

            store.basketInfo = await getBasketInformation({
                // @ts-ignore
                lang: window.Shop.lang.name,
                // @ts-ignore
                currency: window.Shop.values.currency
            });

            $emit('basket:update');
        }
    };

    public iziMobileLink = () => {
        // @ts-ignore
        const isProduction = Shop.iziIsSandbox !== '1';
        const link = isProduction ? 'izilink' : 'izilinksandbox';

        return Promise.resolve({
            link: `inpost://${link}?basket_id=${this.inpostBasketId}`
        });
    };
}
