export const INPOST_WIDGET_BASKET_EVENTS = {
    basketDeleted: 'basketDeleted',
    basketProductChanged: 'basketProductChanged',
    orderCreated: 'orderCreated'
};

export const INPOST_JS_WIDGET_URL = {
    sandbox: 'https://sandbox-inpostpay-widget-v2.inpost.pl/inpostpay.widget.v2.js',
    production: 'https://inpostpay-widget-v2.inpost.pl/inpostpay.widget.v2.js'
};
